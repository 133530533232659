import React from "react";
import { useRef } from "react";
// import { Fade, Slide } from "react-reveal";
import emailjs from "@emailjs/browser";

function Contact(props) {
  const form = useRef();

  const confirmEmail = () => {
    alert("Your Message has been sent.");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r1awxx8",
        "template_cvhq7pq",
        form.current,
        "7PFx-Mkkdsqji7YWc"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  if (!props.data) return null;

  const name = props.data.name;
  // const street = props.data.address.street;
  const city = props.data.address.city;
  const state = props.data.address.state;
  // const zip = props.data.address.zip;
  const phone = props.data.phone;
  const message = props.data.contactmessage;

  return (
    <section id="contact">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Vertically center the content
          height: "100vh", // This will center the content vertically on the viewport
        }}
        className="row"
      >
        <form
          ref={form}
          onSubmit={sendEmail}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center form fields horizontally and vertically
            width: "90vw", // Adjust this value as needed for the desired width
            maxWidth: "600px", // Limit the form width while adapting to different screens
            margin: "0 auto", // Center the form horizontally using margin
            padding: "20px", // Add some space around the form
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)", // Add a subtle shadow
            borderRadius: "5px", // Add rounded corners
          }}
        >
          {/* <Fade bottom duration={1000}> */}
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p
                style={{ paddingLeft: "15px", paddingTop: "25px" }}
                className="lead"
              >
                {message}
              </p>
            </div>
          </div>
          {/* </Fade> */}
          <label style={{ width: "60%" }}>Name</label>
          <input
            type="text"
            name="user_name"
            style={{
              padding: "5px",
              justifySelf: "left",
              marginBottom: "10px",
              borderRadius: "3px",
              border: "none",
              boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
            }}
          />
          <label style={{ width: "60%" }}>Email</label>
          <input
            type="email"
            name="user_email"
            style={{
              padding: "5px",
              marginBottom: "10px",
              borderRadius: "3px",
              border: "none",
              boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
            }}
          />
          <label style={{ width: "60%" }}>Message</label>
          <textarea
            name="message"
            style={{
              padding: "5px",
              marginBottom: "10px",
              borderRadius: "3px",
              border: "none",
              boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
            }}
          />
          <input
            type="submit"
            value="Send"
            style={{
              padding: "5px 10px",
              marginTop: "10px",
              backgroundColor: "#008CBA",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={confirmEmail}
          />
          {/* <Slide right duration={1000}> */}
          <aside className="four columns footer-widgets text-center">
            <div className="widget widget_contact">
              <h4>Address and Phone</h4>
              <p className="address">
                {name}
                <br />
                {city}, {state}
                <br />
                <span>{phone}</span>
              </p>
            </div>
          </aside>
          {/* </Slide> */}
        </form>
      </div>
    </section>
  );
}

export default Contact;
