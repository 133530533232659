import React, { Component } from "react";
// import Slide from "react-reveal";
import myVideo from "./ipad1.mp4";
import Video from "./360.mp4";
import "./MobileResume.css"; // Add a separate CSS file for mobile styles

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    // const skillmessage = this.props.data.skillmessage;
    const booths = this.props.data.education.map(function (booths) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          key={booths.header}
        >
          <div>
            <h2 style={{ color: "#010522 " }}>{booths.header}</h2>
            <br />
            <p style={{ color: "#010522 " }} className="info">
              {booths.firstLine}
              {booths.secondLine}
            </p>
            <p style={{ color: "#010522 " }}>{booths.thirdLine}</p>
          </div>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h2 style={{ color: "#010522 " }}>{work.company}</h2>
          <p style={{ color: "#010522 " }} className="info">
            {work.title}
            <em className="date">{work.years}</em>
          </p>
          <p style={{ color: "#010522 " }}>{work.description}</p>
        </div>
      );
    });

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor(); // for the skills bar
    //   const className = "bar-expand " + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section style={{ backgroundColor: "#9E9257" }} id="resume">
        {/* <Slide left duration={1300}> */}
        <div className="row education">
          <div className="twelve columns">
            {" "}
            {/* Remove flex styles */}
            <h1 style={{ textAlign: "left", padding: 0 }}>
              <br />
              <span>iPad Photobooth</span>
              <br />
              <br />
              <video width="75%" height="auto" controls>
                {" "}
                {/* Adjust video size */}
                <source src={myVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </h1>
            <div className="nine columns main-col">
              <div style={{ textAlign: "left" }} className="row item">
                <div
                  className="twelve columns"
                  style={{ padding: "0px", width: "100%" }}
                >
                  {booths}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Slide> */}

        {/* <Slide left duration={1300}> */}
        <div className="row education">
          <div className="twelve columns">
            {" "}
            {/* Remove flex styles */}
            <h1 style={{ textAlign: "left", padding: 0 }}>
              <span>360 Videobooth</span>
              <br />
              <br />
              <video width="75%" height="auto" controls>
                {" "}
                {/* Adjust video size */}
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </h1>
            <div className="nine columns main-col">
              <div style={{ textAlign: "left" }} className="row item">
                <div className="twelve columns" style={{ padding: "0px" }}>
                  {work}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Slide> */}
      </section>
    );
  }
}

export default Resume;
