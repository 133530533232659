import React, { Component } from "react";
// import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    // const profilepic = "images/logos/Color logo - no background.svg";
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const contractdownload = this.props.data.contractdownload;

    return (
      <section style={{ backgroundColor: "#313131" }} id="about">
        {/* <Fade duration={1000}> */}
        <div className="row">
          <div
            className="nine columns main-col"
            style={{ textAlign: "left", width: "100%" }}
          >
            {/* <div style={{ paddingLeft: "35%" }}>
                <img
                  style={{
                    width: "175px",
                    height: "150px",
                    borderRadius: "0",
                  }}
                  className="profile-pic"
                  src={profilepic}
                  alt="Profile Pic"
                />
              </div> */}
            <br />
            <h2 style={{ color: "#9E9257" }}>About Us</h2>

            <p style={{ color: "#9E9257" }}>
              {bio}
              {bio.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </p>
            <div className="row">
              <div
                style={{ color: "#9E9257" }}
                className="columns contact-details"
              >
                <h2 style={{ color: "#9E9257" }}> Contact Details</h2>
                <p style={{ color: "#9E9257" }} className="address">
                  <span>{name}</span>
                  <br />
                  <span>
                    {street}
                    <br />
                    {city} {state}, {zip}
                  </span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
              <div className="columns download">
                <p style={{ color: "#9E9257" }}>
                  <a
                    href={contractdownload}
                    className="button"
                    style={{ color: "#9E9257" }}
                  >
                    <i className="fa fa-file-pdf-o"></i>View Contract
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </section>
    );
  }
}

export default About;
